import FinishPaymentSection from "./FinishPaymentSection";

const DiscountPaymentSection = ({ handlerSubmit }) => {
  const processPayment = async () => {
    const success = handlerSubmit();
  }
  return (
    <FinishPaymentSection
      buttonLabel={'Continuar'}
      enable={true}
      processPayment={() => processPayment()}
    />
  )
}

export default DiscountPaymentSection