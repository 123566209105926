import { CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts';
import { vehicleTypes } from '../../reducers/types';
import { getTheftReportVehicleData } from '../../services/VehicleServices';
import TheftReportServiceMainSection from './TheftReportServiceMainSection';
import './ServiceSideSection.css';
import './TheftReportServiceSideSection.css';
import { toTitleCase } from '../../util/helpers';
let theftReportTimer = false;

const TheftReportServiceSideSection = ({ vehicle, selected, setMainPanel, open }) => {
  let { dispatch } = useContext(AppContext);
  let { services: { reporte_robo } } = vehicle;
  const loading = Object.entries(reporte_robo).length == 0;
  useEffect(() => () => theftReportTimer = true, [])

  useEffect(() => {
    if (open && loading) {
      theftReportTimer = false;
      getTheftReportData()
    } else
      theftReportTimer = true
  }, [open, loading])

  useEffect(() => {
    if (selected == 'theft_report') {
      setMainPanel(<TheftReportServiceMainSection reporte_robo={reporte_robo} />)
    }
  }, [selected])

  const getTheftReportData = async () => {
    let { success, data } = await getTheftReportVehicleData(vehicle.id)
    if (success && data.robbery_report && Object.entries(data.robbery_report).length != 0) {
      dispatch({
        type: vehicleTypes.updateTheftReport,
        payload: {
          id: vehicle.id,
          reporte_robo: data.robbery_report
        }
      });
    } else {
      setTimeout(() => !theftReportTimer && getTheftReportData(), 1000 * 5);
    }
  }

  return (
    <div className="service-side-section">
      {
        loading ? (
          <div className="loading-container">
            <p className='title'>¡Estamos procesando tu información, espera un momento!</p>
            <div className="loading">
              <CircularProgress className="loading-icon" size={30} />
            </div>
          </div>
        ) : (
          <>
            <p className="title">Reporte de Robo</p>
            <TheftReportSideSection
              name='PGJ'
              service={reporte_robo.PGJ_reporte}
            />
            <TheftReportSideSection
              name='OCRA'
              service={reporte_robo.OCRA_reporte}
            />
            <TheftReportSideSection
              name='RAPI'
              service={reporte_robo.RAPI}
            />
          </>
        )
      }
    </div>
  )
}

const TheftReportSideSection = ({ name, service }) => {
  const status = service ? toTitleCase(service.value.replace('ESTADO VEHÍCULO: ', '')) : '-';
  return (
    <p>
      <span className='side-label'>Reporte en {name}</span>
      <span className={`side-variable ${service && service.alert ? 'alert' : ''}`}>
        {status}
      </span>
    </p>
  )
}


export default TheftReportServiceSideSection
