import './ServiceMainSection.css';
import MarkdownPreview from '@uiw/react-markdown-preview';
import useHeight from '../../hooks/useHeight';
import PlateRenewSection from './plateRenew/PlateRenewSection';

const PlateRenewServiceMainSection = ({ expiration, expire, template_description }) => {
  const [ref, height] = useHeight();
  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>Renovación de placa</span>
      </div>
      <div className="service-overflow" style={{ height: `${height}px` }}>
        <div className="service-main-content">
          <PlateRenewSection
            expiration={expiration}
            expire={expire}
          />
        </div>
        <div className="service-main-footer">
          <MarkdownPreview source={template_description} />
        </div>
      </div>
    </div>
  )
}

export default PlateRenewServiceMainSection
