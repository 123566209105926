import './ServiceMainSection.css';
import './PenalityServiceMainSection.css';
import PenalityEstateServiceSection from './PenalityEstateServiceSection';
import useHeight from '../../hooks/useHeight';

const PenalityServiceMainSection = ({ vehicle, status }) => {
  let { estate, services: { multa_transito } } = vehicle;
  const [ref, height] = useHeight();
  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-overflow" style={{ height: `${height}px` }}>
        {
          multa_transito.edomex && (
            <PenalityEstateServiceSection
              name={estate}
              status={status}
              multas={multa_transito.edomex}
            />
          )
        }
        {
          multa_transito.cdmx && (
            <PenalityEstateServiceSection
              name={'Ciudad de México'}
              status={status}
              multas={multa_transito.cdmx}
            />
          )
        }
      </div>
    </div >
  )
}

export default PenalityServiceMainSection
