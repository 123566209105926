import './ServiceMainSection.css';
import './EnvironmentalSanctionServiceMainSection.css';
import useHeight from '../../hooks/useHeight';
import NoPenalityEnvironmentalSanctionSection from './environmentalSanction/NoPenalityEnvironmentalSanctionSection';
import FooterEnvironmentalSanctionSection from './environmentalSanction/FooterEnvironmentalSanctionSection';
import EnvironmentalSanctionSection from './environmentalSanction/EnvironmentalSanctionSection';

const EnvironmentalSanctionServiceMainSection = ({ multas, status }) => {
  const [ref, height] = useHeight();
  const noPenality = multas.length == 0;
  if (status)
    multas = multas.filter(({ situacion }) => situacion.includes(status))

  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>Sanción ambiental en la Ciudad de México</span>
      </div>
      <div className="service-overflow" style={{ height: `${height}px` }}>
        {
          noPenality ? (
            <NoPenalityEnvironmentalSanctionSection />
          ) : (
            multas.map((item) => <EnvironmentalSanctionSection {...item} />)
          )
        }
        <FooterEnvironmentalSanctionSection />
      </div>
    </div>
  )
}

export default EnvironmentalSanctionServiceMainSection
