import { useState, useEffect, useContext, useRef } from "react";
import { Button, TextField, Grid, CircularProgress, MenuItem, InputAdornment, ToggleButton, FormControlLabel, ToggleButtonGroup, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppContext } from "../contexts";
import { vehicleTypes, appTypes } from "../reducers/types";
import { Controller, useForm, useWatch } from "react-hook-form";
import motoBrands from "../data/motoBrands";
import carBrands from "../data/carBrands";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { daysInMonth, getYears, scrollToElement } from "../util/helpers";
import carModels from "../data/carModels";
import motoModels from "../data/motoModels";
import AlertaSwitch from "../component/controls/AlertaSwitch";
import monthNames from "../data/monthNames";
import "./RegisterCarDetailPage.css";
import { isMobile } from "react-device-detect";
import NumberFormat from "react-number-format";
import { createVehicle, getVehicleByNIV, getVehicleByPlate, updateVehicle } from "../services/VehicleServices";
import ForeignVehicleDialog from "../component/dialogs/ForeignVehicleDialog";
import RegisteredPlateDialog from "../component/dialogs/RegisteredPlateDialog";
import { Images } from "../util";
import moment from "moment";
import PlateNotFoundDialog from "../component/dialogs/PlateNotFoundDialog";
import BeforeExitMessage from "../helpers/BeforeExitMessage";
import NIVTooltip from "../component/controls/NIVTooltip";

const days = daysInMonth(1, 2021);
const years = getYears(moment().year() - 6, moment().year());
const vehicleYears = getYears(1990, moment().year() + 1);

const RegisterCarDetailPage = ({ history }) => {
  const texts = useSelector((store) => store.page.register_flow.register_car_detail);
  const { app: { user, vehicles, estateList }, dispatch } = useContext(AppContext);
  const { vehicleId } = useParams();
  const currentVehicle = vehicles.find((vehicle) => vehicle.id == vehicleId);
  const tarjeta_circulacion = false;
  const [showForeignVehicleDialog, setShowForeignVehicleDialog] = useState({ open: false })
  const [showRegisteredPlateDialog, setShowRegisteredPlateDialog] = useState({ open: false })
  const [showPlateNotFoundDialog, setShowPlateNotFoundDialog] = useState({ open: false })
  const [vehicle, setVehicle] = useState(currentVehicle)
  const [estatePlate, setEstatePlate] = useState(vehicle.estatePlate);
  const { handleSubmit, control, setError, setValue, clearErrors } = useForm({
    defaultValues: {
      niv: vehicle.niv,
      plate: vehicle.plate,
      manual: vehicle.manual,
      withNIV: vehicle.withNIV,
      brand: vehicle.brand,
      year: vehicle.year,
      model: vehicle.model,
      version: vehicle.version,
      hologram: vehicle.hologram,
      hologramMonth: vehicle.hologramMonth,
      hologramYear: vehicle.hologramYear,
      not_remember: vehicle.not_remember,
      permanent: vehicle.permanent,
      circulationDay: vehicle.circulationDay,
      circulationMonth: vehicle.circulationMonth,
      circulationYear: vehicle.circulationYear,
      newVehicle: vehicle.newVehicle,
      lastService: vehicle.lastService,
      serviceDay: vehicle.serviceDay,
      serviceMonth: vehicle.serviceMonth,
      serviceYear: vehicle.serviceYear,
      serviceKm: vehicle.serviceKm,
    }
  });
  const [manual, withNIV, plate, niv, brand, year, model, version, newVehicle] = useWatch({ control, name: ['manual', 'withNIV', 'plate', 'niv', 'brand', 'year', 'model', 'version', 'newVehicle'] });
  const verificationData = useWatch({ control, name: ['hologram', 'hologramMonth', 'hologramYear', 'not_remember'] });
  const circulationData = useWatch({ control, name: ['permanent', 'circulationDay', 'circulationMonth', 'circulationYear'] });
  const serviceData = useWatch({ control, name: ['newVehicle', 'lastService', 'serviceDay', 'serviceMonth', 'serviceYear', 'serviceKm'] });

  const [hologram, , , not_remember] = verificationData;
  const [permanent] = circulationData;

  const [plateNotFound, setPlateNotFound] = useState(vehicle.complete);
  const [showSearchPlateButton, setShowSearchPlateButton] = useState(true)
  const [showSearchNIVButton, setShowSearchNIVButton] = useState(true)
  const [enableSearchPlateButton, setEnableSearchPlateButton] = useState(false)
  const [loadingSearchPlate, setLoadingSearchPlate] = useState(false)
  const [showPlateData, setShowPlateData] = useState(false)
  const [sections, setSections] = useState(false);
  const [successPlateSection, setSuccessPlateSection] = useState(false);
  const [successSectionVerification, setSuccessSectionVerification] = useState(vehicle.type == 1);
  const [successSectionService, setSuccessSectionService] = useState(vehicle.type == 0);
  const [successSectionCirculation, setSuccessSectionCirculation] = useState(!tarjeta_circulacion);
  const [activateSpinner, setActivateSpinner] = useState(false);
  const enableSave = successPlateSection && successSectionVerification && successSectionCirculation && successSectionService;

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: user && user.registered,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: `<span>${vehicle.name}</span>: ${vehicle.estate}`,
        subtitle: texts.subtitle_desktop,
        back: () => history.push('/register_car')
      }
    })
  }, [vehicle])

  useEffect(() => {
    if (showSearchPlateButton) {
      setShowPlateData(false);
      setSections(false);
      setSuccessPlateSection(false);
    }
    setEnableSearchPlateButton(plate?.length >= 5 || niv?.length >= 5);
    clearErrors('plate', 'niv')
  }, [plate, niv])

  useEffect(() => {
    if (manual) {
      // setValue('brand', '');
      // setValue('model', '');
      // setValue('year', '');
      // setValue('version', '');
      setValue('withNIV', false);
      setShowSearchNIVButton(false);
      setShowSearchPlateButton(false);
      setSections(true);
    } else {
      setSections(false);
      setShowPlateData(false);
      setSuccessPlateSection(false);
      setShowSearchNIVButton(true);
      setShowSearchPlateButton(true);
    }
  }, [manual])

  useEffect(() => {
    setShowPlateData(false);
    if (withNIV) {
      setValue('brand', '');
      setValue('model', '');
      setValue('year', '');
      setValue('version', '');
      setValue('niv', '');
      setSuccessPlateSection(false);
      setSections(false);
      setShowSearchPlateButton(true);
    } else {
      if (!manual) {
        setSections(false);
        setSuccessPlateSection(false);
      }
    }
  }, [withNIV])

  useEffect(() => {
    if (manual)
      setSuccessPlateSection(plate && brand && year && model);
  }, [plate, manual, brand, year, model, version]);

  useEffect(() => {
    if (vehicle.type == 0) {
      const [, month, year] = verificationData;
      if (hologram == 'exempt')
        setSuccessSectionVerification(true)
      else {
        if (not_remember) {
          if (month || year) {
            setValue('hologramMonth', "")
            setValue('hologramYear', "")
          }
          setSuccessSectionVerification(hologram && true)
        } else {
          setSuccessSectionVerification(hologram && month && year && true)
        }
      }
    }
  }, [verificationData]);

  let firstFocus = true
  const circulationDayRef = useRef(null)
  useEffect(() => {
    if(!tarjeta_circulacion)
      return;
    const [permanent, day, month, year] = circulationData;
    if (permanent === 'false') {
      if (firstFocus) {
        setTimeout(() => {
          firstFocus = false;
          scrollToElement(circulationDayRef, -300);
        }, 200);
      }
      setSuccessSectionCirculation(day && month && year && true)
    } else if (permanent === 'true')
      setSuccessSectionCirculation(true)
    else
      setSuccessSectionCirculation(false)
  }, [circulationData]);

  useEffect(() => {
    if (vehicle.type == 1) {
      const [, lastService, day, month, year, km] = serviceData;
      if (newVehicle)
        setSuccessSectionService(true)
      else
        setSuccessSectionService(lastService && day && month && year && km && true)
    } else {
      setSuccessSectionService(true)
    }
  }, [serviceData]);

  useEffect(() => {
    if (plate) {
      if (!manual) {
        setSuccessPlateSection(true);
        setShowPlateData(true);
      }
      setSections(true);
    }
  }, [])

  const handlerSearchPlate = async () => {
    if (!withNIV)
      if (!validatePlate(plate))
        return
    setLoadingSearchPlate(true);
    let { success, message, data } = withNIV ? await getVehicleByNIV(user.id, niv) : await getVehicleByPlate(user.id, plate);
    if (success) {
      clearErrors('plate', 'niv');
      setPlateNotFound(true);
      data.modelo = data.modelo.replace(/\([^]*\)/, '').trim();
      if (data.clase == "" && data.numPuertas != "")
        data.clase = data.numPuertas == "0" ? "MOTOCICLETA" : 'AUTOMOVIL'
      if (data.clase != "") {
        let type = data.clase == "MOTOCICLETA" ? 1 : 0;
        setVehicle((vehicle) => ({
          ...vehicle,
          type: type,
          name: `${type == 0 ? 'Auto' : 'Moto'} ${vehicle.number}`
        }))
        setSuccessSectionVerification(type == 1);
        setSuccessSectionService(type == 0)
      }
      if (withNIV) {
        setShowSearchPlateButton(false);
        setValue('withNIV', false);
        data.placa = plate;
        data.entidad = vehicle.estate;
        setPlateData(data)
        setLoadingSearchPlate(false);
        return;
      }
      if (vehicle.estate.toUpperCase() == data.entidad.toUpperCase()) {
        setPlateData(data)
        setLoadingSearchPlate(false);
        return;
      }
      if (data.entidad.toUpperCase() == 'SIN INFORMACION') {
        setPlateData({ ...data, entidad: vehicle.estate })
        setLoadingSearchPlate(false);
        return;
      }
      const inEstates = estateList.find(({ name }) => name.toUpperCase() == data.entidad.toUpperCase());
      if (inEstates) {
        setVehicle((vehicle) => ({
          ...vehicle,
          estate: inEstates.name,
        }))
        setPlateData(data)
      } else {
        setShowForeignVehicleDialog({
          open: true, data: {
            plateEstate: data.entidad,
            vehicleEstate: vehicle.estate,
            data
          }
        })
      }
    } else {
      if (message == 'plate_not_found') {
        // setShowPlateNotFoundDialog({
        //   open: true
        // })
        setPlateNotFound(true);
        setValue('manual', true);
        // setValue('withNIV', true);
      } else if (message == 'niv_not_found') {
        setError("niv", {
          type: "manual",
          message: 'No encontramos el NIV de tu vehículo'
        });
      } else if (message == 'plate_exists_in_user') {
        setShowRegisteredPlateDialog({ open: true, data: { plate: true } })
      } else if (message == 'vin_exists_in_user') {
        setShowRegisteredPlateDialog({ open: true })
      } else if (message == 'timeout of 20000ms exceeded') {
        setError(withNIV ? "niv" : 'plate', {
          type: "manual",
          message: 'La solicitud ha tardado más de lo esperado, verifica tu conexión y vuelve a intentarlo'
        });
      } else {
        console.error(message)
        setError(withNIV ? "niv" : 'plate', {
          type: "manual",
          message: 'Ha ocurrido un error por favor inténtalo de nuevo',
        });
      }
    }
    setLoadingSearchPlate(false);
  };

  const setPlateData = (data) => {
    setValue('plate', data.placa == "" ? plate : data.placa);
    setValue('niv', data.vin);
    setValue('brand', data.marca);
    setValue('model', data.modelo);
    setValue('year', data.anioModelo);
    setValue('version', data.version);
    setEstatePlate(data.entidad);
    setSuccessPlateSection(true);
    setShowPlateData(true);
    setSections(true);
  }

  const validatePlate = (plate) => {
    if (!/(([A-Z]+[0-9]+)|([0-9]+[A-Z]+))([A-Z0-9]*)/.test(plate)) {
      setError('plate', {
        type: "manual",
        message: 'Placa no válida',
      });
      return false;
    }
    return true
  }

  const onSubmit = async (form, e) => {
    e.preventDefault();
    if (!enableSave) {
      handlerSearchPlate();
      return;
    }
    if (!validatePlate(form.plate))
      return
    setActivateSpinner(true);
    delete vehicle.class
    const req = {
      ...vehicle,
      ...form,
      id: undefined,
      estatePlate: estatePlate || vehicle.estate
    }
    const { success, message, data } = vehicle.complete ? await updateVehicle(vehicle.id, req) : await createVehicle(user.id, req);
    if (success) {
      history.push("/register_car");
      dispatch({
        type: vehicleTypes.updateVehicle,
        payload: {
          ...req,
          id: vehicle.id,
          newId: vehicle.complete ? false : data.id,
          complete: true,
        }
      })
    } else {
      setShowRegisteredPlateDialog({ open: true, data: { plate: true } })
      console.log(message);
    }
    setActivateSpinner(false);
  };

  const handlerForeignVehicleSelected = ({ data }) => {
    if (withNIV) {
      setShowSearchPlateButton(false);
      setValue('withNIV', false);
    }
    setShowForeignVehicleDialog({ open: false });
    setPlateData(data);
    setTimeout(() => {
      setSuccessPlateSection(true);
      setShowPlateData(true);
      setSections(true);
    }, 100);

  }

  const handlerForeignVehicleCancel = () => {
    history.push('/find_estate')
  }

  return (
    <>
      <div className={`register-car-detail-container ${plateNotFound ? '' : 'plateNotFound'}`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`register-car-detail-section-container ${successPlateSection ? 'active' : 'inactive'}`}>
            <Grid className="register-car-detail-verification-content" container spacing={4} >
              <Grid container item spacing={2} justify="flex-start">
                <Grid item container xs={12} md={plateNotFound ? 7 : 12} order={{ xs: 2, md: 1 }}>
                  {
                    withNIV ? (
                      <Controller
                        name="niv"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <TextField
                            label={isMobile ? 'Ingresa tu NIV' : 'Ingresa tu NIV (Número de Serie)'}
                            fullWidth
                            autoFocus={!isMobile}
                            variant="outlined"
                            value={value}
                            onChange={(e) => onChange(e.target.value?.toUpperCase().replace(/[^A-Z0-9]/g, ''))}
                            error={!!error}
                            helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">
                                {
                                  showSearchPlateButton && (
                                    <button type="button" className='register-car-detail-search-plates-button'
                                      onClick={() => handlerSearchPlate()}
                                      disabled={!enableSearchPlateButton}
                                    >
                                      Buscar
                                      {loadingSearchPlate && <CircularProgress className="register-car-detail-search-plates-button-loading" size={20} color='inherit' />}
                                    </button>
                                  )
                                }
                              </InputAdornment>,
                            }}
                          />
                        )}
                      />
                    ) : (
                      <div style={{ width: '100%' }}>
                        <Controller
                          name="plate"
                          control={control}
                          render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
                            <TextField
                              label={isMobile ? 'Ingresa tu placa' : 'Ingresa tu número de placa'}
                              fullWidth
                              autoFocus={!isMobile}
                              inputRef={ref}
                              variant="outlined"
                              value={value}
                              onChange={(e) => onChange(e.target.value?.toUpperCase().replace(/[^A-Z0-9]/g, ''))}
                              error={!!error}
                              helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">
                                  {
                                    showSearchPlateButton && (
                                      <button type="button" className='register-car-detail-search-plates-button'
                                        onClick={() => handlerSearchPlate()}
                                        disabled={!enableSearchPlateButton}
                                      >
                                        Buscar
                                        {loadingSearchPlate && <CircularProgress className="register-car-detail-search-plates-button-loading" size={20} color='inherit' />}
                                      </button>
                                    )
                                  }
                                </InputAdornment>,
                              }}
                            />
                          )}
                        />
                      </div>
                    )
                  }
                </Grid>
                {
                  plateNotFound && (
                    <Grid item container xs={12} md={5} order={{ xs: 1, md: 2 }} justifyContent="end" alignContent="flex-start" rowSpacing={1}>
                      <Grid container item xs={12} justifyContent="flex-end">
                        <Controller
                          name="manual"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <FormControlLabel
                              value="start"
                              className="register-car-detail-switch-text"
                              control={<AlertaSwitch className="register-car-detail-switch" onChange={onChange} checked={value} />}
                              label="Registro manual"
                              labelPlacement="start"
                            />
                          )}
                        />
                      </Grid>
                      {
                        showSearchNIVButton && (
                          <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                            <Controller
                              name="withNIV"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <NIVTooltip
                                    title={<img src={Images[`NIV_${vehicle.estate.replace(/ /g, '_').toUpperCase()}`]} />}
                                  >
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                      <FontAwesomeIcon icon='question-circle' size={'xs'} style={{ marginRight: '5px', cursor: 'pointer' }} />
                                    </IconButton>
                                  </NIVTooltip>
                                  <FormControlLabel
                                    value="start"
                                    className="register-car-detail-switch-text"
                                    control={
                                      <AlertaSwitch
                                        className="register-car-detail-switch"
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        checked={field.value}
                                      />
                                    }
                                    label="Ingresar NIV (Número de Serie)"
                                    labelPlacement="start"
                                  />
                                </>
                              )}
                            />
                          </Grid>
                        )
                      }
                    </Grid>
                  )
                }
              </Grid>
              {
                manual ? (
                  <Grid className="register-car-detail-plates-container" container item spacing={2}>
                    <Grid item xs={12} md={6} >
                      <Controller
                        name="brand"
                        control={control}
                        defaultValue={''}
                        rules={{
                          required: "Debes seleccionar una marca"
                        }}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <TextField
                            select
                            size="small"
                            label="Marca"
                            className={value && "register-car-detail-select-selected"}
                            value={value}
                            onChange={onChange}
                            variant="outlined"
                            fullWidth
                            error={!!error}
                            helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                          >
                            {(vehicle.type == 0 ? carBrands : motoBrands).map((option) => (
                              <MenuItem key={option.id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <Controller
                        name="year"
                        control={control}
                        defaultValue={''}
                        rules={{
                          required: "Debes seleccionar un año'"
                        }}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <TextField
                            select
                            size="small"
                            label="Año"
                            className={value && "register-car-detail-select-selected"}
                            value={value}
                            onChange={onChange}
                            variant="outlined"
                            fullWidth
                            error={!!error}
                            helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                          >
                            {vehicleYears.map((year) => (
                              <MenuItem key={`vehicleYear${year}`} value={year} autoFocus={name == new Date().getFullYear()}>
                                {year}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <Controller
                        name="model"
                        control={control}
                        defaultValue={''}
                        rules={{
                          required: "Debes seleccionar un modelo"
                        }}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <TextField
                            select
                            size="small"
                            label="Modelo"
                            className={value && "register-car-detail-select-selected"}
                            value={value}
                            onChange={onChange}
                            variant="outlined"
                            fullWidth
                            error={!!error}
                            helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                          >
                            {(vehicle.type == 0 ? carModels : motoModels).filter((model) => model.brand_id == (
                              vehicle.type == 0 ?
                                carBrands.find(({ name }) => name == brand)?.id :
                                motoBrands.find(({ name }) => name == brand)?.id
                            )).map((option) => (
                              <MenuItem key={option.id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <Controller
                        name="version"
                        control={control}
                        defaultValue={''}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <TextField
                            size="small"
                            label="Versión (Opcional)"
                            className={value && "register-car-detail-select-selected"}
                            value={value}
                            onChange={onChange}
                            variant="outlined"
                            fullWidth
                            error={!!error}
                            helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  showPlateData && (
                    <Grid className="register-car-detail-plates-container" container item spacing={2}>
                      <Grid item xs={12} md={6}>
                        <div className="register-car-detail-plates-content" >
                          <p className="register-car-detail-plates-title">Marca:</p>
                          <p className="register-car-detail-plates-text"> {brand} </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="register-car-detail-plates-content" >
                          <p className="register-car-detail-plates-title">Modelo:</p>
                          <p className="register-car-detail-plates-text"> {model} </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="register-car-detail-plates-content" >
                          <p className="register-car-detail-plates-title">Año:</p>
                          <p className="register-car-detail-plates-text"> {year} </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="register-car-detail-plates-content" >
                          <p className="register-car-detail-plates-title">Versión:</p>
                          <p className="register-car-detail-plates-text"> {version} </p>
                        </div>
                      </Grid>
                    </Grid>
                  )
                )
              }
            </Grid>
          </div>
          {
            !(showPlateData || manual) && (
              <div className="footer">
                <p>* Al ingresar el número de tu placa o NIV, autorizas que Alertamiauto.com pueda consultar los datos de tu vehículo en el Registro Público Vehicular (REPUVE)</p>
              </div>
            )
          }
          {
            sections && (
              <>
                {
                  vehicle.type == 0 && (
                    <div className={`register-car-detail-section-container ${successSectionVerification ? 'active' : 'inactive'}`}>
                      <Grid className="register-car-detail-verification-content" container spacing={3}>
                        <Grid item xs={12} md={6} container spacing={3}>
                          <Grid className="register-car-detail" item xs={12}>
                            <p className="register-car-detail-title">
                              Selecciona el holograma de verificación
                            </p>
                          </Grid>
                          <Grid className="register-car-detail-select-content" item xs={12}>
                            <Controller
                              name="hologram"
                              control={control}
                              rules={{
                                required: "Debes seleccionar un tipo de verificación"
                              }}
                              render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <ToggleButtonGroup className='register-car-detail-buttons-content' size="small" value={value} onChange={onChange} exclusive={true}>
                                  <ToggleButton value="exempt" key="exempt">Exento</ToggleButton>,
                                  <ToggleButton value="00" key="00">00</ToggleButton>,
                                  <ToggleButton value="0" key="0">0</ToggleButton>,
                                  <ToggleButton value="1" key="1">1</ToggleButton>
                                  <ToggleButton value="2" key="2">2</ToggleButton>
                                </ToggleButtonGroup>
                              )}
                            />
                          </Grid>
                        </Grid>
                        {
                          hologram != 'exempt' && (
                            <Grid item xs={12} md={6} container spacing={3}>
                              <Grid className="register-car-detail" item xs={12}>
                                <p className="register-car-detail-title">
                                  Ingresa la fecha de la última verificación
                                </p>
                              </Grid>
                              <Grid className="register-car-detail-select-content" item xs={4}>
                                <Controller
                                  name="hologramMonth"
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                    <TextField
                                      select
                                      size="small"
                                      label="Mes"
                                      disabled={not_remember}
                                      className={value && "register-car-detail-select-selected"}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e)
                                      }}
                                      variant="outlined"
                                      fullWidth
                                    >
                                      {monthNames.map((name) => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  )}
                                />
                              </Grid>
                              <Grid className="register-car-detail-select-content" item xs={4} style={{ paddingLeft: '10px' }}>
                                <Controller
                                  name="hologramYear"
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                    <TextField
                                      select
                                      size="small"
                                      label="Año"
                                      disabled={not_remember}
                                      className={value && "register-car-detail-select-selected"}
                                      value={value}
                                      onChange={onChange}
                                      variant="outlined"
                                      fullWidth
                                    >
                                      {years.map((name) => (
                                        <MenuItem key={name} value={name} autoFocus={name == new Date().getFullYear()}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  )}
                                />
                              </Grid>
                              <Grid className="register-car-detail-select-content" item xs={4} style={{ paddingLeft: '10px' }}>
                                <Controller
                                  name="not_remember"
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                    <ToggleButtonGroup
                                      className='register-car-detail-buttons-content'
                                      size="small"
                                      value={value}
                                      onChange={(e) => onChange(value ? false : e.target.value)}
                                      exclusive
                                    >
                                      <ToggleButton value="true" key="true" sx={{ fontSize: '12px' }}>No Recuerdo</ToggleButton>,
                                    </ToggleButtonGroup>
                                  )}
                                />
                              </Grid>
                            </Grid>
                          )
                        }
                      </Grid>
                    </div>
                  )
                }
                {
                  tarjeta_circulacion && (
                    <div className={`register-car-detail-section-container ${successSectionCirculation ? 'active' : 'inactive'}`}>
                      <Grid className="register-car-detail-circulation-card-content" container spacing={3}>
                        <Grid item xs={12} md={6} container spacing={3}>
                          <Grid className="register-car-detail" item xs={12}>
                            <p className="register-car-detail-title">
                              ¿Tu tarjeta de circulación es permanente?
                            </p>
                          </Grid>
                          <Grid className="register-car-detail-select-content" item xs={12}>
                            <Controller
                              name="permanent"
                              control={control}
                              rules={{
                                required: "Debes seleccionar un tipo de verificación"
                              }}
                              render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <ToggleButtonGroup className='register-car-detail-buttons-content' size="small" value={value} onChange={onChange} exclusive={true}>
                                  <ToggleButton value="true" key="yes">Si</ToggleButton>,
                                  <ToggleButton value="false" key="no">No</ToggleButton>,
                                </ToggleButtonGroup>
                              )}
                            />
                          </Grid>
                        </Grid>
                        {
                          permanent === 'false' && (
                            <Grid item xs={12} md={6} container spacing={3}>
                              <Grid className="register-car-detail" item xs={12}>
                                <p className="register-car-detail-title">
                                  Ingresa la fecha de expedición
                                </p>
                              </Grid>
                              <Grid className="register-car-detail-select-content" item xs={4}>
                                <Controller
                                  name="circulationDay"
                                  control={control}
                                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <TextField
                                      ref={circulationDayRef}
                                      select
                                      size="small"
                                      label="Día"
                                      className={value && "register-car-detail-select-selected"}
                                      value={value}
                                      onChange={onChange}
                                      variant="outlined"
                                      fullWidth
                                      error={!!error}
                                      helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                                    >
                                      {days.map((name) => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  )}
                                />
                              </Grid>
                              <Grid className="register-car-detail-select-content" item xs={4}>
                                <Controller
                                  name="circulationMonth"
                                  control={control}
                                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <TextField
                                      select
                                      size="small"
                                      label="Mes"
                                      className={value && "register-car-detail-select-selected"}
                                      value={value}
                                      onChange={onChange}
                                      variant="outlined"
                                      fullWidth
                                      error={!!error}
                                      helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                                    >
                                      {monthNames.map((name) => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  )}
                                />
                              </Grid>
                              <Grid className="register-car-detail-select-content" item xs={4}>
                                <Controller
                                  name="circulationYear"
                                  control={control}
                                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <TextField
                                      select
                                      size="small"
                                      label="Año"
                                      className={value && "register-car-detail-select-selected"}
                                      value={value}
                                      onChange={onChange}
                                      variant="outlined"
                                      fullWidth
                                      error={!!error}
                                      helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                                    >
                                      {years.map((name) => (
                                        <MenuItem key={name} value={name} autoFocus={name == new Date().getFullYear()}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  )}
                                />
                              </Grid>
                            </Grid>
                          )
                        }
                      </Grid>
                    </div>
                  )
                }
                {
                  vehicle.type == 1 && (
                    <div className={`register-car-detail-section-container ${successSectionService ? 'active' : 'inactive'}`}>
                      <Grid className="register-car-detail-service-content" container spacing={3}>
                        <Grid container item xs={12} spacing={2} justify="flex-start">
                          <Grid className="register-car-detail" item container xs={12} md={7} order={{ xs: 2, md: 1 }}>
                            <p className="register-car-detail-title">
                              ¿Cuál fue el último servicio de tu moto?
                            </p>
                          </Grid>
                          <Grid item container xs={12} md={5} order={{ xs: 1, md: 2 }} justifyContent="end" alignContent="flex-start" rowSpacing={1}>
                            <Grid container item xs={12} justifyContent="flex-end">
                              <Controller
                                name="newVehicle"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <FormControlLabel
                                    value="start"
                                    className="register-car-detail-switch-text"
                                    control={<AlertaSwitch className="register-car-detail-switch" onChange={onChange} checked={value} />}
                                    label="Mi moto es nueva"
                                    labelPlacement="start"
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          {
                            !newVehicle && (
                              <Grid container item xs={12} spacing={3} order={3}>
                                <Grid className="register-car-detail-select-content" item xs={12}>
                                  <Controller
                                    name="lastService"
                                    control={control}
                                    rules={{
                                      required: "Debes seleccionar un tipo de verificación"
                                    }}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                      <>
                                        <ToggleButtonGroup className='register-car-detail-buttons-content last-service-buttons' size="small" value={value} onChange={onChange} exclusive={true}>
                                          <ToggleButton className="last-service-button" value="1" key="1">1er. Servicio</ToggleButton>
                                          <ToggleButton className="last-service-button" value="2" key="2">2do. Servicio</ToggleButton>
                                          <ToggleButton className="last-service-button" value="3" key="3">3er. Servicio</ToggleButton>
                                          <ToggleButton className="last-service-button desktop" value="4" key="4">4to. Servicio</ToggleButton>
                                          <ToggleButton className="last-service-button desktop" value="5" key="5">5to. Servicio</ToggleButton>
                                          <ToggleButton className="last-service-button desktop" value="6" key="6">6 o más</ToggleButton>
                                        </ToggleButtonGroup>
                                        <ToggleButtonGroup className='register-car-detail-buttons-content last-service-buttons mobile' size="small" value={value} onChange={onChange} exclusive={true}>
                                          <ToggleButton className="last-service-button" value="4" key="4">4to. Servicio</ToggleButton>
                                          <ToggleButton className="last-service-button" value="5" key="5">5to. Servicio</ToggleButton>
                                          <ToggleButton className="last-service-button" value="6" key="6">6 o más</ToggleButton>
                                        </ToggleButtonGroup>
                                      </>
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            )
                          }
                        </Grid>
                        {
                          !newVehicle && (
                            <>
                              <Grid container item xs={12} md={6} spacing={3}>
                                <Grid className="register-car-detail" item xs={12}>
                                  <p className="register-car-detail-title">
                                    Ingresa la fecha de tu último servicio / mantenimiento
                                  </p>
                                </Grid>
                                <Grid className="register-car-detail-select-content" container item xs={4} alignContent="flex-end">
                                  <Controller
                                    name="serviceDay"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                      <TextField
                                        select
                                        size="small"
                                        label="Día"
                                        className={value && "register-car-detail-select-selected"}
                                        value={value}
                                        onChange={onChange}
                                        variant="outlined"
                                        fullWidth
                                        error={!!error}
                                        helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                                      >
                                        {days.map((name) => (
                                          <MenuItem key={name} value={name}>
                                            {name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  />
                                </Grid>
                                <Grid className="register-car-detail-select-content" container item xs={4} alignContent="flex-end">
                                  <Controller
                                    name="serviceMonth"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                      <TextField
                                        select
                                        size="small"
                                        label="Mes"
                                        className={value && "register-car-detail-select-selected"}
                                        value={value}
                                        onChange={onChange}
                                        variant="outlined"
                                        fullWidth
                                        error={!!error}
                                        helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                                      >
                                        {monthNames.map((name) => (
                                          <MenuItem key={name} value={name}>
                                            {name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  />
                                </Grid>
                                <Grid className="register-car-detail-select-content" container item xs={4} alignContent="flex-end">
                                  <Controller
                                    name="serviceYear"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                      <TextField
                                        select
                                        size="small"
                                        label="Año"
                                        className={value && "register-car-detail-select-selected"}
                                        value={value}
                                        onChange={onChange}
                                        variant="outlined"
                                        fullWidth
                                        error={!!error}
                                        helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                                      >
                                        {years.map((name) => (
                                          <MenuItem key={name} value={name} autoFocus={name == new Date().getFullYear()}>
                                            {name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container item xs={12} md={6} spacing={3}>
                                <Grid className="register-car-detail" item xs={12}>
                                </Grid>
                                <Grid className="register-car-detail-select-content" item xs={12}>
                                  <Controller
                                    name="serviceKm"
                                    control={control}
                                    rules={{
                                      required: "Debes seleccionar un tipo de verificación"
                                    }}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                      <NumberFormat
                                        customInput={TextField}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        label="Kilometraje actual"
                                        className={"register-car-detail-service-km " + (value && "register-car-detail-select-selected")}
                                        value={value}
                                        inputProps={{ inputMode: "decimal", }}
                                        onChange={onChange}
                                        variant="outlined"
                                        fullWidth
                                        error={!!error}
                                        helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                                        InputProps={{
                                          endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          )
                        }
                      </Grid>
                    </div>
                  )
                }
                <div className="register-car-detail-button-container">
                  <Button className="alerta-purple-button" disabled={!enableSave} type="submit" >
                    <span className="alerta-purple-button-text">Guardar</span>
                    {
                      activateSpinner && (
                        <span className="alerta-purple-button-progress">
                          <CircularProgress size={30} color='inherit' />
                        </span>
                      )
                    }
                  </Button>
                </div>
              </>
            )
          }
        </form>
      </div>
      <ForeignVehicleDialog
        state={showForeignVehicleDialog}
        onSuccess={handlerForeignVehicleSelected}
        onClose={handlerForeignVehicleCancel}
      />
      <RegisteredPlateDialog
        state={showRegisteredPlateDialog}
        onClose={() => setShowRegisteredPlateDialog({ open: false })}
      />
      <PlateNotFoundDialog
        state={showPlateNotFoundDialog}
        onClose={() => setShowPlateNotFoundDialog({ open: false })}
      />
      <BeforeExitMessage />
    </>
  );
};

export default RegisterCarDetailPage;
