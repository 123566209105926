import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'
import './FinishPaymentSection.css'

const FinishPaymentSection = ({ buttonLabel, amount, enable, processPayment }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [check, setCheck] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false);
  }, [check])

  const onPaid = async() => {
    setError(!check);
    if(check){
      setLoading(true);
      await processPayment();
      setLoading(false);
    }
  }
  return (
    <div>
      <div className='legals-container'>
        <FormControl required error={!!error} >
          <FormControlLabel
            control={<Checkbox checked={check} onChange={() => setCheck(!check)} />}
            label={
              <p>
                Estoy de acuerdo y acepto los
                <strong onClick={() => history.push('/terminos_condiciones')}> Términos y Condiciones </strong> y el
                <strong onClick={() => history.push('/aviso_privacidad')}> Aviso de Privacidad </strong> del servicio de
                Alertamiauto.com
              </p>
            }
          />
        </FormControl>
        {
          error && (
            <FormHelperText>
              <FontAwesomeIcon icon='exclamation-circle' />
              Debes aceptar la opción para continuar
            </FormHelperText>
          )
        }
      </div>

      <Button fullWidth
        className="alerta-purple-button"
        disabled={!(check && enable)} 
        onClick={onPaid}
      >
        {
          amount == null ? buttonLabel : (
            <NumberFormat
              className="alerta-purple-button-text"
              value={amount}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={'Pagar $'}
            />
          )
        }
        {
          loading && (
            <span className="alerta-purple-button-progress">
              <CircularProgress size={30} color='inherit' />
            </span>
          )
        }
      </Button>
    </div>
  )
}

export default FinishPaymentSection