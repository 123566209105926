import useHeight from '../../hooks/useHeight';
import TheftReportSection from './theftReport/TheftReportSection';
import './ServiceMainSection.css';
import './TheftReportServiceMainSection.css';

const TheftReportServiceMainSection = ({ reporte_robo }) => {
  const [ref, height] = useHeight();
  
  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>Reporte de robo</span>
      </div>
      <div className="service-overflow" style={{ height: `${height}px` }}>
        <TheftReportSection
          service={reporte_robo.PGJ_reporte}
          name={(alert) => `${alert ? '' : 'la '}Procuraduría General de Justicia (PGJ)`}
          footer={(alert) =>
            `El vehículo ${alert ? '' : 'no '}cuenta con reporte de robo, conforme a la información que 
            suministran las Instituciones de Seguros con base en el Artículo 23, 
            Fracción III, Inciso b de la Ley de Registro Público Vehicular.`
          }
        />
        <TheftReportSection
          service={reporte_robo.OCRA_reporte}
          name={(alert) => `${alert ? '' : 'la '}Oficina Coordinadora de Riesgos Asegurados (OCRA)`}
          footer={(alert) =>
            `El vehículo ${alert ? '' : 'no '}cuenta con reporte de robo, conforme a la información que 
            suministran las Instituciones de Seguros con base en el Artículo 23, 
            Fracción III, Inciso b de la Ley de Registro Público Vehicular.`
          }
        />
        <TheftReportSection
          service={reporte_robo.RAPI}
          name={(alert) => `${alert ? '' : 'la '}Registro de Automotores de Procedencia Ilícita (RAPI)`}
          footer={(alert) => `El vehículo ${alert ? '' : 'no '}cuenta con reporte de procedencia ilícita.`}
        />
      </div>
    </div >
  )
}

export default TheftReportServiceMainSection
