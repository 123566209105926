import axios from "axios";
import { URL_API } from "../util/Configuration";
import { authHeader, handlerError, handlerResponse, service } from "./helpers/serviceConfig";

export const getVehicleByPlate = (userId, plate) => {
  return service('get', `${URL_API}/user/${userId}/plates/${plate}`, {
    auth: true,
    config: { timeout: 30000 }
  })
}

export const getVehicleByNIV = (userId, niv) => {
  return service('get', `${URL_API}/user/${userId}/niv/${niv}`, {
    auth: true,
    config: { timeout: 30000 }
  })
}

export const createVehicle = (userId, vehicle) => {
  return axios.post(`${URL_API}/user/${userId}/vehicle`, vehicle, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const updateVehicle = (id, vehicle) => {
  return axios.patch(`${URL_API}/vehicle/${id}`, vehicle, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const deleteVehicle = (id) => {
  return axios.delete(`${URL_API}/vehicle/${id}`, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const getPendingVehicles = (userId) => {
  return service('get', `${URL_API}/user/${userId}/vehicles`, { auth: true })
}

export const getVehicleAlertData = (id) => {
  return service('get', `${URL_API}/vehicle/${id}/alert`, { auth: true })
}

export const cancelVehicleMembership = (id) => {
  return service('patch', `${URL_API}/vehicle/${id}/cancelmembership`, { auth: true })
}

export const addServiceVehicle = (id, service) => {
  return axios.post(`${URL_API}/vehicle/${id}/addservice`, service, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const addAdditionalUserToVehicle = (id, user) => {
  return axios.patch(`${URL_API}/vehicle/${id}/extrauser`, user, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const removeAdditionalUserToVehicle = (id) => {
  return axios.delete(`${URL_API}/vehicle/${id}/extrauser`, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const setVerificationVehicle = (id) => {
  return axios.post(`${URL_API}/vehicle/${id}/verificacion`, {}, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const getFotocivicaVehicleData = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/alert/fotocivica`, {
    auth: true,
  })
}

export const getTenenciaVehicleData = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/alert/tenenciarefrendo`, {
    auth: true,
  })
}
export const getInfraccionesVehicleData = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/alert/infracciones`, {
    auth: true,
  })
}

export const getCirculationCardVehicleData = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/alert/tarjetacirculacion`, {
    auth: true,
  })
}

export const getPlateRenewVehicleData = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/alert/platerenewal`, {
    auth: true,
  })
}

export const getTheftReportVehicleData = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/alert/robberyreport`, {
    auth: true,
  })
}

export const getEnvironmentalSanctionVehicleData = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/alert/medioambiente`, {
    auth: true,
  })
}

export const getInsureVehicleData = (vehicleId) => {
  // return service('get', `${URL_API}/vehicle/${vehicleId}/alert/insure`, {
  //   auth: true,
  // })
  return {
    success: true,
    data: {
      seguro: {
        poliza: '0000000000',
        vigencia: '31-01-2023',
        estatus: false,
      }
    }
  }
}