import './ServiceMainSection.css';
import MarkdownPreview from '@uiw/react-markdown-preview';
import useHeight from '../../hooks/useHeight';
import CirculationCardSection from './circulationCard/CirculationCardSection';

const CirculationCardServiceMainSection = ({ foreigner, expiration, expire, template_description }) => {
  const [ref, height] = useHeight();
  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>Tarjeta de circulación</span>
      </div>
      {
       foreigner ? (
          <>
            <div className="alert-content-section" style={{ marginBottom: '10px', marginTop: '20px' }}>
              <p className="alert-title">¡Tu placa no está registrada en el sistema de la Ciudad de México!</p>
            </div>
            <div className="content-section">
              <span className="main-label verification-label" style={{ fontSize: '10px', padding: '0px 20px' }}>
                En cuanto tengamos a tu Estado disponible en nuestro sistema, actualizaremos la información y te notificamos para que estés enterado.
              </span>
            </div>
          </>
        ) : (
          <div className="service-overflow" style={{ height: `${height}px` }}>
            <div className="service-main-content">
              <CirculationCardSection 
                expiration={expiration} 
                expire={expire}
              />
            </div>
            <div className="service-main-footer">
              <MarkdownPreview source={template_description} />
            </div>
          </div>

        )
      }
    </div>
  )
}

export default CirculationCardServiceMainSection
