import { CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts';
import { vehicleTypes } from '../../reducers/types';
import { getEnvironmentalSanctionVehicleData } from '../../services/VehicleServices';
import './ServiceSideSection.css';
import './EnvironmentalSanctionServiceSideSection.css';
import EnvironmentalSanctionServiceMainSection from './EnvironmentalSanctionServiceMainSection';
let enviromentalSanctionTimer = false;

const EnvironmentalSanctionServiceSideSection = ({ vehicle, selected, setMainPanel, open }) => {
  let { dispatch } = useContext(AppContext);
  let { services: { medioambiente } } = vehicle;
  const loading = Object.entries(medioambiente).length == 0;
  const multas = loading ? [] : Array.isArray(medioambiente) ? medioambiente.filter(({ situacion }) => situacion) : [];

  const noPaid = !loading && multas.length == 0 ? 0 : multas.filter(({ situacion }) => situacion.includes('No pagada')).length;
  const paid = !loading && multas.length == 0 ? 0 : multas.filter(({ situacion }) => situacion.includes('Pagada')).length;
  const [status, setStatus] = useState(null)

  useEffect(() => {
    enviromentalSanctionTimer = false;
    return () => {
      enviromentalSanctionTimer = true;
    }
  }, [])

  useEffect(() => {
    if (open && loading) {
      enviromentalSanctionTimer = false;
      getEnvironmentalSanctionData();
    } else
      enviromentalSanctionTimer = true;
  }, [open, loading])

  useEffect(() => {
    if (selected == 'environmental_sanction') {
      setMainPanel(<EnvironmentalSanctionServiceMainSection multas={multas} status={status} />)
    } else {
      setStatus(null)
    }
  }, [selected, status])

  const getEnvironmentalSanctionData = async () => {
    let { success, data } = await getEnvironmentalSanctionVehicleData(vehicle.id)
    if (success && Object.entries(data.medioambiente).length != 0) {
      dispatch({
        type: vehicleTypes.updateEnvironmentalSanction,
        payload: {
          id: vehicle.id,
          medioambiente: data.medioambiente
        }
      });
    } else {
      setTimeout(() => {
        if (!enviromentalSanctionTimer)
          getEnvironmentalSanctionData();
      }, 1000 * 5);
    }
  }

  return (
    <div className="service-side-section">
      {
        loading ? (
          <div className="loading-container">
            <p className='title'>¡Estamos procesando tu información, espera un momento!</p>
            <div className="loading">
              <CircularProgress className="loading-icon" size={30} />
            </div>
          </div>
        ) : (
          <>
            <p className="title">Sanciones Ambientales</p>
            <p
              className={`penality-status ${status == 'No pagada' ? 'selected' : ''}`}
              onClick={() => noPaid != 0 && setStatus('No pagada')}
            >
              <span className='side-label'>No pagadas</span>
              <span className={`${noPaid != 0 ? 'alert' : ''} side-variable`}>{noPaid == 0 ? 'No tienes ' : 'Tienes ' + noPaid} multa{noPaid == 1 ? '' : 's'}</span>
            </p>
            <p
              className={`penality-status ${status == 'Pagada' ? 'selected' : ''}`}
              onClick={() => paid != 0 && setStatus('Pagada')}
            >
              <span className='side-label'>Pagadas</span>
              <span className="side-variable">{paid == 0 ? 'No tienes ' : 'Tienes ' + paid} multa{paid == 1 ? '' : 's'}</span>
            </p>
          </>
        )
      }
    </div>
  )
}

export default EnvironmentalSanctionServiceSideSection
