import { useContext, useEffect, useState } from 'react';
import PlateRenewServiceMainSection from './PlateRenewServiceMainSection';
import moment from 'moment';
import './ServiceSideSection.css';
import { getPlateRenewVehicleData } from '../../services/VehicleServices';
import { vehicleTypes } from '../../reducers/types';
import { CircularProgress } from '@mui/material';
import { AppContext } from '../../contexts';
let plateRenewTimer = false;
const PlateRenewServiceSideSection = ({ vehicle, selected, setMainPanel, open }) => {
  let { dispatch } = useContext(AppContext);
  let { services: { renovacion_placa } } = vehicle;
  const loading = Object.entries(renovacion_placa).length == 0;
  const validity = 5;
  const expiration = !loading && moment(renovacion_placa.fechaFinVigenciaFormat, 'DD-MM-YYYY');
  const expire = !loading && expiration.isBefore(moment());

  useEffect(() => {
    plateRenewTimer = false;
    return () => {
      plateRenewTimer = true;
    }
  }, [])

  useEffect(() => {
    if (open && loading) {
      plateRenewTimer = false;
      getPlateRenewData()
    } else
      plateRenewTimer = true
  }, [open, loading])

  useEffect(() => {
    if (selected == 'plate_renew')
      setMainPanel(<PlateRenewServiceMainSection
        expiration={expiration}
        expire={expire}
        template_description={renovacion_placa.template_description}
      />)
  }, [selected, vehicle])

  const getPlateRenewData = async () => {
    const { success, data } = await getPlateRenewVehicleData(vehicle.id)
    if (success && Object.entries(data.platerenewal).length != 0) {
      dispatch({
        type: vehicleTypes.updatePlateRenew,
        payload: {
          id: vehicle.id,
          renovacion_placa: data.platerenewal
        }
      });
    } else {
      setTimeout(() => {
        if (!plateRenewTimer)
          getPlateRenewData();
      }, 1000 * 5);
    }
  }

  return (
    <div className="service-side-section">
      {
        loading ? (
          <div className="loading-container">
            <p className='title'>¡Estamos procesando tu información, espera un momento!</p>
            <div className="loading">
              <CircularProgress className="loading-icon" size={30} />
            </div>
          </div>
        ) : (
          <>
            <p className="title">Renovación de Placa</p>
            <p>
              <span className="side-label">Vencimiento</span>
              <span className="side-variable">{expiration.isValid() ? expiration.format('DD[ de ]MMMM[ de ]YYYY') : '-'}</span>
            </p>
            <p>
              <span className="side-label">Vigencia</span>
              <span className="side-variable">{`${validity} año${validity == 1 ? '' : 's'}`}</span>
            </p>
            <p>
              <span className="side-label">Estatus Actual</span>
              {
                expiration.isValid() ?
                  <span className={`side-variable ${expire ? 'alert' : ''}`}>{expire ? 'Vencida' : 'Vigente'}</span> :
                  <span className="side-variable">-</span>
              }
            </p>
          </>
        )
      }
    </div >
  )
}

export default PlateRenewServiceSideSection
