import { CircularProgress, Grid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Images } from '../../util'
import { Animated } from "react-animated-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { vehicleTypes } from '../../reducers/types';
import { AppContext } from '../../contexts';
import { getVehicleAlertData } from '../../services/VehicleServices';
import { scrollToElement, toEstateTitleCase } from '../../util/helpers';
import { isMobile } from 'react-device-detect';
import NoCirculateServiceSideSection from './NoCirculateServiceSideSection'
import VerificationServiceSideSection from './VerificationServiceSideSection'
import TenureServiceSideSection from './TenureServiceSideSection'
import PenalityServiceSideSection from './PenalityServiceSideSection'
import FotocivicasServiceSideSection from './FotocivicasServiceSideSection'
import CirculationCardServiceSideSection from './CirculationCardServiceSideSection'
import MaintenanceServiceSideSection from './MaintenanceServiceSideSection'
import PlateRenewServiceSideSection from './PlateRenewServiceSideSection';
import TheftReportServiceSideSection from './TheftReportServiceSideSection';
import EnvironmentalSanctionServiceSideSection from './EnvironmentalSanctionServiceSideSection';
import InsureServiceSideSection from './insure/InsureServiceSideSection';
import './PrincipalServicesSection.css'

const PrincipalServicesSection = ({ vehicle, open, handlerOpen }) => {
  let { dispatch } = useContext(AppContext);
  let { id, type, estatePlate, estate, services } = vehicle;
  const foreigner = toEstateTitleCase(estatePlate) != estate;
  const [selected, setSelected] = useState(null)
  const [mainPanel, setMainPanel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const principalServices = [
    {
      code: 'no_circulate',
      label: "Hoy no circula",
      image: Images.NOT_CIRCULATE,
      click: true,
      component: type == 0 && services && services.no_circula && <NoCirculateServiceSideSection
        selected={selected}
        setMainPanel={setMainPanel}
        vehicle={vehicle}
      />,
    },
    {
      code: 'verification',
      label: "Verificación",
      image: Images.CHECKUP,
      click: true,
      component: type == 0 && services && services.verificacion && <VerificationServiceSideSection
        selected={selected}
        setMainPanel={setMainPanel}
        vehicle={vehicle}
      />,
    },
    {
      code: 'tenure',
      label: "Tenencia / Refr.",
      click: foreigner || (services && services.tenencia_refrendo && Object.entries(services.tenencia_refrendo).length != 0),
      image: type == 0 ? Images.TENURE : Images.TENURE_MOTO,
      component: services && services.tenencia_refrendo && <TenureServiceSideSection
        open={open}
        selected={selected}
        foreigner={foreigner}
        setMainPanel={setMainPanel}
        vehicle={vehicle}
      />,
    },
    {
      code: 'penality',
      label: "Infracciones",
      click: services && Object.entries(services.multa_transito).length != 0,
      image: Images.PENALTY,
      component: services && services.multa_transito && <PenalityServiceSideSection
        open={open}
        selected={selected}
        setMainPanel={setMainPanel}
        vehicle={vehicle}
      />,
    },
    {
      code: 'environmental_sanction',
      label: "Sanción amb.",
      image: Images.ENVIRONMENTAL_SANCTION,
      click: services && services.medioambiente && Object.entries(services.medioambiente).length != 0,
      component: services && services.medioambiente && <EnvironmentalSanctionServiceSideSection
        open={open}
        selected={selected}
        setMainPanel={setMainPanel}
        vehicle={vehicle}
      />,
    },
    {
      code: 'fotocivicas',
      label: "FotoCívicas",
      image: Images.FOTOCIVICAS,
      click: foreigner || (services && services.fotocivica && Object.entries(services.fotocivica).length != 0),
      component: type == 0 && services && services.fotocivica && <FotocivicasServiceSideSection
        open={open}
        selected={selected}
        foreigner={foreigner}
        setMainPanel={setMainPanel}
        vehicle={vehicle}
      />,
    },
    {
      code: 'circulation_card',
      label: "T. de circulación",
      image: Images.CIRCULATION_CARD,
      click: foreigner || (services && services.tarjeta_circulacion && services.tarjeta_circulacion.expire != null),
      component: services && services.tarjeta_circulacion && <CirculationCardServiceSideSection
        open={open}
        foreigner={foreigner}
        selected={selected}
        setMainPanel={setMainPanel}
        vehicle={vehicle}
      />,
    },
    {
      code: 'maintenance',
      label: "Mantenimiento",
      image: type == 0 ? Images.MAINTENANCE : Images.MAINTENANCE_MOTO,
      click: true,
      component: type == 1 && services && services.mantenimiento && <MaintenanceServiceSideSection
        selected={selected}
        setMainPanel={setMainPanel}
        vehicle={vehicle}
      />,
    },
    {
      code: 'plate_renew',
      label: "Placas",
      image: Images.WHITE_PLATE,
      click: services && services.renovacion_placa && Object.entries(services.renovacion_placa).length != 0,
      component: services && services.renovacion_placa && <PlateRenewServiceSideSection
        open={open}
        selected={selected}
        setMainPanel={setMainPanel}
        vehicle={vehicle}
      />,
    },
    {
      code: 'insure',
      label: "Seguro",
      image: Images.INSURE,
      click: services && services.seguro && Object.entries(services.seguro).length != 0,
      component: services && services.seguro && <InsureServiceSideSection
        open={open}
        selected={selected}
        setMainPanel={setMainPanel}
        vehicle={vehicle}
      />,
    },
    {
      code: 'theft_report',
      label: "Reporte de robo",
      image: Images.THEFT_REPORT,
      click: services && services.reporte_robo && Object.entries(services.reporte_robo).length != 0,
      component: services && services.reporte_robo && <TheftReportServiceSideSection
        open={open}
        selected={selected}
        setMainPanel={setMainPanel}
        vehicle={vehicle}
      />,
    },
  ]
  useEffect(() => {
    if (open) {
      if (selected == null) {
        const first = principalServices.filter(({ component, click }) => component && click)[0];
        if (first)
          setSelected(first.code)
      } else {
        setSelected(selected)
      }
    }
  }, [services])

  useEffect(async () => {
    if (open) {
      console.log(services);
      if (services == undefined) {
        setError(false);
        setLoading(true);
        const { success, message, data } = await getVehicleAlertData(vehicle.id);
        if (success) {
          dispatch({
            type: vehicleTypes.setServices,
            payload: {
              id: vehicle.id,
              services: {
                no_circula: data.no_circula,
                verificacion: data.verificacion,
                tenencia_refrendo: data.tenencia_refrendo,
                multa_transito: data.multa_transito,
                mantenimiento: data.mantenimiento,
                tarjeta_circulacion: data.tarjeta_circulacion,
                fotocivica: data.fotocivica,
                renovacion_placa: data.plate_renewal,
                reporte_robo: data.robbery_report,
                medioambiente: data.medioambiente,
              }
            }
          });
        } else {
          console.log(message);
          setError(true);
        }
      } else {
        if (!isMobile) {
          const first = principalServices.filter(({ component, click }) => component && click)[0];
          if (first)
            setSelected(first.code)
        }
      }
    } else {
      setSelected(null)
    }
    setLoading(false)
  }, [open])

  const handlerSelectService = (code) => {
    code = isMobile && selected == code ? null : code;
    setSelected(code)
    if (open) {
      if (isMobile) {
        if (code) {
          setTimeout(() => {
            scrollToElement({ current: document.getElementById(`${id}_${code}`) }, 25);
          }, 100);
        } else {
          scrollToElement({ current: document.getElementById(`services_${id}`) }, 25);
        }
      } else {
        scrollToElement({ current: document.getElementById(id) }, 25);
      }
    }
  }

  return (
    <div id={`services_${id}`} className="principal-services-section">
      <Animated animationIn="fadeIn" animationOut="fadeOut" animationOutDuration={0} isVisible={open} >
        <div>
          {
            loading ? (
              <div className="principal-services-loading-container">
                <CircularProgress size={60} />
              </div>
            ) : (
              <>
                {
                  error ? (
                    <div>Error</div>
                  ) : (
                    <div className="principal-services-data-section">
                      <Grid container spacing={3} alignItems="stretch">
                        <Grid container item className="mobile">
                          <p className="title">Selecciona un servicio</p>
                        </Grid>
                        <Grid container item xs={12} md={5} rowSpacing={2} alignContent="flex-start">
                          {
                            principalServices.filter(({ component }) => component).map(({ code, label, image, component, click }) => (
                              <>
                                <Grid container item
                                  id={`${id}_${code}`}
                                  columnSpacing={1}
                                  className="side-service-container"
                                  onClick={() => click && handlerSelectService(code)}
                                >
                                  <Grid container item xs={4}>
                                    <div className={`card ${selected == code ? 'selected' : ''}`}>
                                      <img src={image} />
                                      <span> {label} </span>
                                    </div>
                                  </Grid>
                                  <Grid container item xs={8}>
                                    <div className={`side-component ${selected == code ? 'selected' : ''}`}>
                                      {component}
                                    </div>
                                  </Grid>
                                </Grid>
                                {
                                  selected == code && (
                                    <Grid container item
                                      xs={12}
                                      md={7}
                                      className="mobile"
                                    >
                                      <div className={`service-selected ${type == 0 ? 'auto' : 'moto'}`} >
                                        {mainPanel}
                                      </div>
                                    </Grid>
                                  )
                                }
                              </>
                            ))
                          }
                        </Grid>
                        <Grid container item xs={12} md={7} className="desktop">
                          <div className={`service-selected ${type == 0 ? 'auto' : 'moto'}`} >
                            {mainPanel}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )
                }
              </>
            )
          }
        </div>
      </Animated>
      <div className="show-more-container" onClick={() => handlerOpen(vehicle.id)}>
        {
          open ? (
            <>
              <span>Ver menos</span>
              <FontAwesomeIcon className="icon" icon="chevron-up" size="lg" />
            </>
          ) : (
            <>
              <span>Ver más</span>
              <FontAwesomeIcon className="icon" icon="chevron-down" size="lg" />
            </>
          )
        }
      </div>
    </div>
  )
}

export default PrincipalServicesSection
