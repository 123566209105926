import { CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts';
import { vehicleTypes } from '../../reducers/types';
import { getFotocivicaVehicleData } from '../../services/VehicleServices';
import FotocivicasServiceMainSection from './FotocivicasServiceMainSection';
import './ServiceSideSection.css';
let fotocivicasTimer = false;

const FotocivicasServiceSideSection = ({ vehicle, foreigner, selected, setMainPanel, open }) => {
  let { dispatch } = useContext(AppContext);
  let { services: { fotocivica } } = vehicle;
  const loading = !foreigner && Object.entries(fotocivica).length == 0;
  const puntos = fotocivica.puntos || 0;
  const infracciones = 10 - puntos < 0 ? abs(10 - puntos) + 10 : 10 - puntos;

  useEffect(() => {
    fotocivicasTimer = false;
    return () => {
      fotocivicasTimer = true;
    }
  }, [])

  useEffect(() => {
    if (selected == 'fotocivicas')
      setMainPanel(<FotocivicasServiceMainSection
        puntos={puntos}
        infracciones={infracciones}
        foreigner={foreigner}
        template_description={fotocivica.template_description}
      />)
  }, [selected])

  useEffect(() => {
    if (open && loading) {
      fotocivicasTimer = false;
      getFotocivicasData()
    } else
      fotocivicasTimer = true
  }, [open, loading])

  const getFotocivicasData = async () => {
    const { success, data } = await getFotocivicaVehicleData(vehicle.id)
    if (success && Object.entries(data.fotocivica).length != 0) {
      dispatch({
        type: vehicleTypes.updateFotocivica,
        payload: {
          id: vehicle.id,
          fotocivica: data.fotocivica
        }
      });
    } else {
      setTimeout(() => {
        if (!fotocivicasTimer)
          getFotocivicasData();
      }, 1000 * 5);
    }
  }

  return (
    <div className="service-side-section">
      {
        loading ? (
          <div className="loading-container">
            <p className='title'>¡Estamos procesando tu información, espera un momento!</p>
            <div className="loading">
              <CircularProgress className="loading-icon" size={30} />
            </div>
          </div>
        ) : (
          <>
            <p className="title">Programa de FotoCívicas (CDMX)</p>
            {
              foreigner ? (
                <div className="without-info-container">
                  <p className='title'>Servicio disponible solo para placas de la Ciudad de México.</p>
                </div>
              ) : (
                <>
                  <p>
                    <span className="side-label">Puntaje actual</span>
                    <span className="side-variable">
                      {
                        puntos ? (<><b>{puntos}</b> puntos</>) : 'Sin Información'
                      }
                    </span>
                  </p>
                  <p>
                    <span className="side-label">Infracciones</span>
                    <span className="side-variable">
                      {
                        puntos ? infracciones : 'Sin Información'
                      }
                    </span>
                  </p>
                </>
              )
            }
          </>
        )
      }
    </div>
  )
}

export default FotocivicasServiceSideSection
