import { Button, Grid } from '@mui/material';
import { useContext, useState } from 'react'
import Moment from 'react-moment';
import moment from 'moment';
import { AppContext } from '../../contexts';
import { Images } from '../../util';
import './VehicleDataSection.css';
import CancelSubscriptionDialog from '../dialogs/CancelSubscriptionDialog';
import RemoveAlertDialog from '../dialogs/RemoveAlertDialog';
import { useHistory } from 'react-router';
import { vehicleTypes } from '../../reducers/types';
import { isMobile } from 'react-device-detect';
import { openPaymentPDF } from '../../services/PaymentServices';
import { toEstateTitleCase } from '../../util/helpers';

const imagePath = "/assets/images/vehicleBrands";

const VehicleDataSection = ({ vehicle }) => {
  let history = useHistory();
  const { app: { user: { name, lastname, email, phone } }, dispatch
  } = useContext(AppContext);
  const { id, brand, model, year, plate, active, membership, estate, estatePlate, paymentOxxo } = vehicle;

  const paymentId = membership ? membership.payment.id : paymentOxxo;
  const end_date = membership?.end_date;
  const start_date = membership?.start_date;
  const paymentType = membership?.payment.type;

  const [showCancelSubscriptionDialog, setShowCancelSubscriptionDialog] = useState({ open: false });
  const [showRemoveVehicleDialog, setShowRemoveVehicleDialog] = useState({ open: false });
  const [notFoundLogo, setNotFoundLogo] = useState(false)
  const typeName = vehicle.type == 0 ? 'CAR' : 'MOTO';
  const logoSRC = `${imagePath}/${brand.toUpperCase().replace(/( |-)/g, '_')}_${typeName}`;
  const notFoundLogoSRC = `${imagePath}/NONE_${typeName}`;
  const end = moment(end_date, "DD/MM/YYYY");
  const start = moment(start_date, "DD/MM/YYYY");

  const handlerRenewSubscription = () => {
    dispatch({
      type: vehicleTypes.updateVehicle,
      payload: {
        id,
        subscription: true
      }
    })
  };

  const handlerCancelSubscription = async () => {
    const { success, message, data } = await cancelVehicleMembership(id);
    if (success) {
      dispatch({
        type: vehicleTypes.updateVehicle,
        payload: {
          id,
          subscription: false
        }
      })
      setShowCancelSubscriptionDialog({ open: false })
    } else {
      console.log(message);
    }
  };

  const handlerRemoveAlert = () => {
    dispatch({
      type: vehicleTypes.deleteVehicle,
      payload: id
    })
    setShowRemoveVehicleDialog({ open: false })
  };

  return (
    <>
      <div className={`vehicle-data-content ${active ? '' : 'inactive'}`} >
        <Grid container item justify="center" rowSpacing={{ xs: 2 }} >
          <Grid container item xs={12} md={6}>
            <Grid container item xs={12} md={3} 
              justifyContent={'center'}
              alignContent={'center'}
              className='logo-container' 
            >
              {
                notFoundLogo ? (
                  <>
                    <img className='mobile' src={`${notFoundLogoSRC}_MOBILE.png`} />
                    <img className='desktop' src={`${notFoundLogoSRC}_DESKTOP.png`} />
                  </>
                ) : (
                  <>
                    <img className='mobile' src={`${logoSRC}_MOBILE.png`} onError={() => setNotFoundLogo(true)} />
                    <img className='desktop' src={`${logoSRC}_DESKTOP.png`} onError={() => setNotFoundLogo(true)} />
                  </>
                )
              }
            </Grid>
            <Grid container item xs={12} md={9} >
              <div className="brand-container">
                <p className="car-label" >
                  {`${brand.toUpperCase()} ${model.toUpperCase()} ${year}`}
                </p>
                <p className="folio">
                  {`Placas: ${plate}`}
                </p>
                <div className="location-container">
                  <img src={Images.LOCATION}></img>
                  <p>{toEstateTitleCase(estatePlate) != estate ? toEstateTitleCase(estatePlate) + "/" : ""}{estate}</p>
                  <img className="image-vehicle-type" src={vehicle.type == 0 ? Images.INACTIVE_CAR : Images.INACTIVE_MOTO}></img>
                </div>
                {
                  active ? (
                    membership == null ? (
                      <>
                        <Button className="action-buttons" onClick={() => history.push(`/edit_alert/${id}`)}>
                          Editar
                        </Button>
                        <Button className="action-buttons" onClick={() => setShowCancelSubscriptionDialog({ open: true })}>
                          Cancelar Suscripción
                        </Button>
                      </>
                    ) : moment().isBefore(end) ? (
                      <>
                        <Button className="action-buttons" onClick={() => history.push(`/edit_alert/${id}`)}>
                          Editar
                        </Button>
                        <Button className="action-buttons" onClick={() => setShowCancelSubscriptionDialog({ open: true })}>
                          Cancelar Suscripción
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button className="action-buttons" onClick={() => handlerRenewSubscription()}>
                          Renovar Suscripción
                        </Button>
                        <Button className="action-buttons" onClick={() => setShowRemoveVehicleDialog({ open: true })}>
                          Eliminar Alerta
                        </Button>
                      </>
                    )
                  ) : (
                    moment().isBefore(end) ? (
                      <>
                        <div className="subscription-canceled-container">
                          <p className="title">Suscripción cancelada</p>
                          <p className="subtitle">Tu suscripción actual tiene una vigencia hasta: </p>
                          <p className="date">
                            <Moment date={end} format="DD /MM/YY" />
                          </p>
                        </div>
                        <Button className="action-buttons" onClick={() => handlerRenewSubscription()}>
                          Renovar Suscripción
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button className="action-buttons">
                          Activar Alerta
                        </Button>
                        <Button className="action-buttons" onClick={() => setShowRemoveVehicleDialog({ open: true })}>
                          Eliminar Alerta
                        </Button>
                      </>
                    )
                  )
                }
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={3} >
            <div className="user-data-container">
              <p className="title">Nombre del solicitante</p>
              <p className="content">{`${name} ${lastname}`}</p>
              <p className="title">Correo electrónico</p>
              <p className="content">{email}</p>
              <p className="title">Número celular</p>
              <p className="content">{phone}</p>
            </div>
          </Grid>
          <Grid container item xs={12} md={3} >
            <div className="payment-data-container">
              <Grid container>
                <Grid item xs={6}>
                  <p className="title">Fecha de Inicio</p>
                  <p className="content">
                    {start_date ? <Moment date={start} format="DD/MM/YY" /> : '-'}
                  </p>
                  <p className="content"></p>
                </Grid>
                <Grid item xs={6}>
                  <p className="title">Vencimiento</p>
                  <p className="content">
                    {end_date ? <Moment date={end} format="DD/MM/YY" /> : '-'}
                  </p>
                  <p className="content"></p>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <p className="title">Método de pago</p>
                <p className="content">
                  {
                    paymentType == 'oxxo' ? 'Efectivo (OXXO Pay)' :
                      paymentType == 'paypal' ? 'Paypal' :
                        paymentType == 'card' ? `Tarjeta Bancaria` :
                          paymentType == 'coupon' ? `Cupón de descuento` : 'Pendiente de Pago'
                  }
                </p>
                <p className="content"></p>
              </Grid>
              <Grid item xs={12}>
                <Button className="print" fullWidth onClick={() => openPaymentPDF(paymentId)}>
                  <img src={Images.PRINT} />
                  <span> Imprimir PDF</span>
                </Button>
              </Grid>
            </div>

          </Grid>
        </Grid>
      </div>
      <CancelSubscriptionDialog
        state={showCancelSubscriptionDialog}
        onSuccess={handlerCancelSubscription}
        onClose={() => setShowCancelSubscriptionDialog({ open: false })}
      />
      <RemoveAlertDialog
        state={showRemoveVehicleDialog}
        onSuccess={handlerRemoveAlert}
        onClose={() => setShowRemoveVehicleDialog({ open: false })}
      />
    </>
  )
}

export default VehicleDataSection
