import { useEffect } from 'react';
import './ServiceSideSection.css';
import { getGummedColor } from '../../util/helpers';
import VerificationServiceMainSection from './VerificationServiceMainSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';


const VerificationServiceSideSection = ({ vehicle, selected, setMainPanel }) => {
  const { services: { verificacion: { hologram, description, first_period, second_period, first, second, first_period_months, second_period_months } } } = vehicle;
  const gummed_color = getGummedColor(description);
  const firstPeriod = first == null ? true : first;
  const secondPeriod = second == null ? true : second;
  const inFirstPeriod = first_period_months.includes(moment().month() + 1);
  const inSecondPeriod = second_period_months.includes(moment().month() + 1);
  useEffect(() => {
    if (selected == 'verification')
      setMainPanel(<VerificationServiceMainSection vehicle={vehicle} />)
  }, [selected, vehicle])

  return (
    <div className="service-side-section">
      <p className="title">
        Holograma {hologram == 'exempt' ? 'Exento' : hologram}
      </p>
      <div className="tag" style={{ backgroundColor: gummed_color }}>Terminación {description}</div>
      {
        hologram != 'exempt' && (
          <>
            <p>
              <span className="side-label">1er</span>
              <span className={`side-variable ${!firstPeriod ? 'verification-in-period' : ''}`}>
                {/* {first == true && <><FontAwesomeIcon className="check-circle" icon="check-circle" /> Verificado</>} */}
                {first == true && 'Verificado'}
                {first == false && (inFirstPeriod ? first_period : 'Vencido')}
                {first == null && first_period}
              </span>
            </p>
            <p style={{ lineHeight: '12px' }}>
              <span className="side-label second-period">2do</span>
              <span className={`side-variable ${!secondPeriod ? 'verification-in-period' : ''}`}>
                {second == true && 'Verificado'}
                {second == false && (inSecondPeriod ? second_period : 'Vencido')}
                {second == null && second_period}
              </span>
            </p>
          </>
        )
      }
    </div>
  )
}

export default VerificationServiceSideSection
