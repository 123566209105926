import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from '@mui/material'
import { Fade } from 'react-awesome-reveal'
import { Images } from '../../util'
import './HomePrincipalServicesSection.css'
const principalServices = [
  { label: "Hoy no circula", image: Images.NOT_CIRCULATE, text: 'En caso de que tu vehículo no circule, nosotros te avisaremos para que no te multen' },
  { label: "Verificación", image: Images.CHECKUP, text: 'Te recordamos cuando tengas que verificar tu auto y evites que te cobren recargos' },
  { label: "Tenencia / Refrendo", image: Images.TENURE, text: 'Te recordamos para que pagues la Tenencia a tiempo, consultes adeudos y desglose de impuestos' },
  { label: "Infracciones", image: Images.PENALTY, text: 'En caso de que tu vehículo registre una multa o tengas una pendiente de pago, te avisamos de inmediato' },
  { label: "FotoCívicas", image: Images.FOTOCIVICAS, text: 'Entérate cuando le quiten puntos a tu placa por cada infracción cometida en la Ciudad de México.' },
  { label: "Tarjeta de circulación", image: Images.CIRCULATION_CARD, text: 'Registra tu tarjeta de circulación y te avisamos cuando debas de hacer el pago y refrendo' },
  { label: "Sanción ambiental", image: Images.ENVIRONMENTAL_SANCTION, text: 'Te avisamos en caso de que tu auto tenga una sanción ambiental en la Ciudad de México' },
  { label: "Renovación de placa", image: Images.WHITE_PLATE, text: 'Si tus placas son del Estado de México, te avisamos cuanto tengas que renovarlas' },
  { label: "Reporte de robo", image: Images.THEFT_REPORT, text: 'Monitoreamos si tu vehículo tiene reporte de robo en la PGJ, OCRA y RAPI de la Fiscalía General de Justicia' },
  { label: "Seguro vehicular", image: Images.INSURE, text: 'Nosotros te avisamos cuando se vaya a vencer la póliza de tu Seguro (auto o moto)' },
  { label: "Mantenimiento", image: Images.MAINTENANCE_MOTO, text: 'Si tienes una moto, te avisamos para que la lleves a su servicio o mantenimiento anual' },
]

const HomePrincipalServicesSection = () => {
  return (
    <div className="home-principal-services-section">
      <Fade direction="up" triggerOnce>
        <p className="home-principal-services-section-title">
          ¿Qué servicios obtengo si contrato una Alerta?
        </p>
      </Fade>
      <Grid container spacing={5}>
        {
          principalServices.map(({ label, image, text }, i) => (
            <Grid item key={`home_principal_service_${i}`} xs={12} sm={4}>
              <Fade direction="up" triggerOnce>
                <div className="home-principal-services-section-card">
                  <img src={image} />
                  <span className="card-title">{label}</span>
                  <span className="card-text">{text}</span>
                </div>
              </Fade>
            </Grid>
          ))
        }
      </Grid>

      <p className="footer-note">
        * Hay Estados en donde algunos de estos servicios no aplican, en ese caso no se mostrará la información correspondiente.
      </p>
    </div>
  )
}

export default HomePrincipalServicesSection
