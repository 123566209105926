import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect';
import { Controller, useForm, useWatch } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import ConektaHelper from '../../../helpers/ConektaHelper';
import { Images } from '../../../util';
import ErrorCardPaymentDialog from '../../dialogs/ErrorCardPaymentDialog';
import FinishPaymentSection from './FinishPaymentSection';
import './CardPaymentSection.css';

const CardPaymentSection = ({ amount, handlerSubmit }) => {
  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      holder: "",
      number: "",
      expiryTime: "",
      cvv: "",
    }
  });
  const watch = useWatch({ control });

  const [enable, setEnabled] = useState(false)
  const [showErrorPayment, setShowErrorPayment] = useState({ open: false })

  useEffect(() => {
    const { holder, number, expiryTime, cvv } = watch;
    setEnabled(holder != '' && number != '' && expiryTime != '' && cvv != '')
  }, [watch])

  const generateToken = (holder, number, expMount, expYear, cvv, callback) => {
    ConektaHelper.initConekta();
    ConektaHelper.tokenize(holder, number, expMount, expYear, cvv,
      (response) => {
        callback(response);
      },
      (response) => {
        callback("error", response);
      })
  }

  const handlerOnTryAgain = () => {
    setShowErrorPayment({ open: false })
    handleSubmit(onSubmit)()
  }

  const onSubmit = ({ number, holder, cvv, expiryTime }) => {
    number = number.replace(/ /g, '');
    const exp = expiryTime.split('/');
    generateToken(holder, number, exp[0], exp[1], cvv,
      ( (response, error) => {
        if (response != "error") {
          const success = handlerSubmit({
            token: response.id,
            lastCardNumbers: number.slice(-4),
          })
          if (!success) {
            setShowErrorPayment({ open: true })
          } 
        } else {
          console.log(error);
          if (error.param.includes('exp_month') || error.param.includes('exp_year'))
            setError('expiryTime', {
              type: "manual",
              message: error.message_to_purchaser,
            });
          else if (error.param.includes('number'))
            setError('number', {
              type: "manual",
              message: error.message_to_purchaser,
            });
          else
            setShowErrorPayment({ open: true })
        }
      }));
  }
  return (
    <>
      <div className="payment-section">
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item container justifyContent={'space-between'}>
            <Grid item>
              <p className='payment-section-title'>Pago con Tarjeta de Crédito / Débito</p>
            </Grid>
            <Grid>
              <img src={Images.CONEKTA_SECURE} alt="conekta" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="holder"
              control={control}
              defaultValue=""
              rules={{
                required: "Debes agregar el nombre de la tarjeta",
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  label="Nombre como aparece en la tarjeta"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  autoFocus={!isMobile}
                  value={value}
                  onChange={(e) => onChange(e.target.value.toUpperCase())}
                  error={!!error}
                  helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="number"
              control={control}
              defaultValue=""
              rules={{
                required: "Debes agregar el número de tarjeta",
                validate: value => /^\d{16}|\d{15}$/.test(value.replace(/ /g, '')) || 'El número de tarjeta no es válido',
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return <NumberFormat
                  customInput={TextField}
                  format="#### #### #### ####"
                  placeholder="0000 0000 0000 0000"
                  label="Número de tarjeta"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  value={value}
                  onChange={onChange}
                  inputProps={{ inputMode: "decimal" }}
                  error={!!error}
                  helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                />
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="expiryTime"
              control={control}
              defaultValue=""
              rules={{
                required: "Debes agregar la vigencia de la tarjeta",
                validate: value => /^\d{4}$/.test(value.replace('/', '')) || 'La vigencia de la tajeta no es válida',
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <NumberFormat
                  customInput={TextField}
                  format="##/##"
                  label="Vigencia"
                  placeholder="MM/AA"
                  mask={['M', 'M', 'A', 'A']}
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="cvv"
              control={control}
              defaultValue=""
              rules={{
                required: "Debes agrgar el código de verificación de la tarjeta",
                pattern: {
                  value: /^\d{3}|\d{4}$/,
                  message: 'El código de verificación de la tajeta no es válido'
                }
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <NumberFormat
                  customInput={TextField}
                  format={(value) => value.trim().length <= 4 ? value.trim() : value.substring(0, 4)}
                  label="CVV"
                  placeholder="000"
                  variant="outlined"
                  type="password"
                  fullWidth
                  autoComplete="off"
                  value={value.trim()}
                  onChange={onChange}
                  error={!!error}
                  helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
      <FinishPaymentSection
        amount={amount}
        enable={enable}
        processPayment={() => handleSubmit(onSubmit)()}
      />
      <ErrorCardPaymentDialog
        state={showErrorPayment}
        onSuccess={() => handlerOnTryAgain()}
        onClose={() => setShowErrorPayment({ open: false })}
      />
    </>
  )
}

export default CardPaymentSection
