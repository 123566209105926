const TenueEDOMEXSection = ({ data }) => {
  const { pĺate: plate, tenencia } = data;
  const concepts = [
    { title: 'TENECIA ESTATAL SERVICIO PARTICULAR' },
    { label: 'Importe', amount: `$ ${tenencia?.impuesto}` },
    { label: 'Actualización', amount: `$ ${tenencia?.actualizacion}` },
    { label: 'Recargo', amount: `$ ${tenencia?.recargos}` },
    { label: 'Condonación', amount: `$ ${tenencia?.subsidio}` },
    { space: 10 },
    { label: 'Total', amount: `$ ${tenencia?.total}` },
    { space: 20 },
    { title: 'REFRENDO ANUAL DE PLACAS SERVICIO PARTICULAR' },
    { label: 'Importe', amount: `$ ${plate?.impuesto}` },
    { label: 'Actualización', amount: `$ ${plate?.actualizacion}` },
    { label: 'Recargo', amount: `$ ${plate?.recargos}` },
    { label: 'Condonación', amount: `$ ${plate?.subsidio}` },
    { space: 10 },
    { label: 'Total', amount: `$ ${plate?.total}` },
  ];
  return (
    <>
      {
        concepts.map(({ label, amount, space, line, title }, i) => (
          title ? (<p className="content-title">{title}</p>) :
            space ? (<div style={{ height: `${space}px` }}></div>) :
              line ? (<hr style={{ margin: "20px 0", border: '0', borderTop: "1px solid #dadada" }} />) :
                (
                  <div key={`tenure-concepts-${i}`} className="content-section">
                    <span className="main-label">{label}</span>
                    {amount && <span className="main-variable">{amount}</span>}
                  </div>
                )
        ))
      }
    </>
  )
}

export default TenueEDOMEXSection