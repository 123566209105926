import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useHeight from '../../hooks/useHeight';
import ServiceErrorSection from './ServiceErrorSection';
import './ServiceMainSection.css';
import TenueCDMXSection from './tenue/TenueCDMXSection';
import TenueEDOMEXSection from './tenue/TenueEDOMEXSection';

const TenureServiceMainSection = ({ tenureSelected, noDebts, foreigner, error }) => {
  const [year, setYear] = useState(null)
  const [tenureData, setTenureData] = useState({})
  const [ref, height] = useHeight();

  useEffect(() => {
    if (tenureSelected) {
      const year = Object.keys(tenureSelected)[0];
      const data = tenureSelected[year]
      setYear(year);
      setTenureData(data)
    }
  }, [tenureSelected])

  return (
    <div className="service-main-section" ref={ref}> 
      <div className="service-main-title">
        <span>
          {
            Object.entries(tenureData).length == 2 ? 'Adeudos ' + year : 'Tenencia / Refrendo'
          }
        </span>
      </div>
      {
        error ? (
          <ServiceErrorSection type={'main'} />
        ) : foreigner ? (
          <>
            <div className="alert-content-section" style={{ marginBottom: '10px', marginTop: '20px' }}>
              <p className="alert-title">¡Tu placa no está registrada en el sistema de la Ciudad de México!</p>
            </div>
            <div className="content-section">
              <span className="main-label verification-label" style={{ fontSize: '10px', padding: '0px 20px' }}>
                En cuanto tengamos a tu Estado disponible en nuestro sistema, actualizaremos la información y te notificamos para que estés enterado.
              </span>
            </div>
          </>
        ) : (
          noDebts ? (
            <>
              <div className="success-content-section">
                <p className="success-title">
                  <span>Tu vehículo no presenta adeudos</span>
                  <FontAwesomeIcon icon="check-circle" size="lg" />
                </p>
              </div>
              <div className="service-main-footer">
                <p className="footer-content">
                  Si usted desea corregir o aclarar un pago ya realizado, puede comunicarse
                  a la Administración Tributaria del Estado más cercana a su domicilio.
                </p>
                <p className="footer-content">
                  La presente información es mostrada sin perjuicio de las facultades de
                  comprobación de las autoridades fiscales.
                </p>
              </div>
            </>
          ) : (
            <div className="service-overflow" style={{ height: `${height}px` }}>
              <div className="service-main-content">
                {
                  Object.entries(tenureData).length == 2 ? 
                    <TenueEDOMEXSection 
                      year={year} 
                      data={tenureData} 
                    /> : 
                    <TenueCDMXSection 
                      year={year} 
                      data={tenureData}
                      buttons={true} 
                    />
                }
              </div>
            </div>
          )
        )
      }
    </div>
  )
}

const TenueCDMX = ({ year, data }) => {
  const paymentFormatPre = 'https://data.finanzas.cdmx.gob.mx/formato_lc/formatos/FormatoGeneral/Genera?';
  const {
    tenencia, actualiza_ten, recargo_ten, total_tenencia,
    derecho, actuliza_derecho, recargo_derecho, total_derechos,
    subsidio, actualizacion, recargos,
    lineacaptura, vigencia, total,
  } = data;
  const concepts = [
    { label: 'Tenencia', amount: `$ ${tenencia}` },
    { label: 'Actualización Tenencia', amount: `$ ${actualiza_ten}` },
    { label: 'Recargo Tenencia', amount: `$ ${recargo_ten}` },
    { label: 'Total Tenencia', amount: `$ ${total_tenencia}` },
    { space: 20 },
    { label: 'Derechos', amount: `$ ${derecho}` },
    { label: 'Actualización Derechos', amount: `$ ${actuliza_derecho}` },
    { label: 'Recargo Derechos', amount: `$ ${recargo_derecho}` },
    { label: 'Total Derechos', amount: `$ ${total_derechos}` },
    { space: 20 },
    { label: 'TOTAL DEL CÁLCULO' },
    { space: 10 },
    { label: 'Impuesto', amount: `$ ${tenencia}` },
    { label: 'Subsidio', amount: `$ ${subsidio}` },
    { label: 'Derechos', amount: `$ ${derecho}` },
    { label: 'Actualización', amount: `$ ${actualizacion}` },
    { label: 'Recargos', amount: `$ ${recargos}` },
    { label: 'Total a pagar', amount: `$ ${total}` },
    { space: 20 },
    { label: 'Línea de captura', amount: lineacaptura },
    { label: 'Valido hasta', amount: vigencia },
  ];
  return (
    <>
      <p className="content-title">Adeudos {year}</p>
      {
        concepts.map(({ label, amount, space }, i) => (
          space ? (
            <div style={{ height: `${space}px` }}></div>
          ) : (
            <div key={`tenure-concepts-${i}`} className="content-section">
              <span className="main-label">{label}</span>
              {amount && <span className="main-variable">{amount}</span>}
            </div>
          )
        ))
      }
      <div className="content-section verification-done-button-container">
        <button
          onClick={() => window.open(`${paymentFormatPre}
                          lc=${data.lineacaptura}
                          &dag_id=${data.dagid}
                          &lcCB=${data.lineacapturaCB}
                          &aniob=${moment(data.vigencia).year()}`,
            '_blank')}
          className="main-button"
        >
          Descargar Formato de Pago
        </button>
      </div>
      <div className="content-section verification-done-button-container">
        <form action='https://data.finanzas.cdmx.gob.mx/pagos/confirmaPagoTenencia' target='_blank' method='POST'>
          <input type="hidden" name="ejercicio" value={data.ejercicio} />
          <input type="hidden" name="lineacaptura" value={data.lineacaptura} />
          <input type="hidden" name="dagid" value={data.dagid} />
          <input type="hidden" name="lineacapturaCB" value={data.lineacapturaCB} />
          <input type="hidden" name="placas" value={data.placas} />
          <input type="hidden" name="fechavencimiento" value={data.fechavencimiento} />
          <input type="hidden" name="total" value={data.total} />
          <input type="hidden" name="lc" value={data.lc} />
          <button
            className="main-button"
            type='submit'
          >
            Pago en Línea
          </button>
        </form>

      </div>
    </>
  )
}

const TenueEDOMEX = ({ year, data }) => {
  const { pĺate: plate, tenencia } = data;
  const concepts = [
    { title: 'TENECIA ESTATAL SERVICIO PARTICULAR' },
    { label: 'Importe', amount: `$ ${tenencia?.impuesto}` },
    { label: 'Actualización', amount: `$ ${tenencia?.actualizacion}` },
    { label: 'Recargo', amount: `$ ${tenencia?.recargos}` },
    { label: 'Condonación', amount: `$ ${tenencia?.subsidio}` },
    { space: 10 },
    { label: 'Total', amount: `$ ${tenencia?.total}` },
    { space: 20 },
    { title: 'REFRENDO ANUAL DE PLACAS SERVICIO PARTICULAR' },
    { label: 'Importe', amount: `$ ${plate?.impuesto}` },
    { label: 'Actualización', amount: `$ ${plate?.actualizacion}` },
    { label: 'Recargo', amount: `$ ${plate?.recargos}` },
    { label: 'Condonación', amount: `$ ${plate?.subsidio}` },
    { space: 10 },
    { label: 'Total', amount: `$ ${plate?.total}` },
  ];
  return (
    <>
      {
        concepts.map(({ label, amount, space, line, title }, i) => (
          title ? (<p className="content-title">{title}</p>) :
            space ? (<div style={{ height: `${space}px` }}></div>) :
              line ? (<hr style={{ margin: "20px 0", border: '0', borderTop: "1px solid #dadada" }} />) :
                (
                  <div key={`tenure-concepts-${i}`} className="content-section">
                    <span className="main-label">{label}</span>
                    {amount && <span className="main-variable">{amount}</span>}
                  </div>
                )
        ))
      }
    </>
  )
}

export default TenureServiceMainSection
