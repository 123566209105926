import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts';
import { vehicleTypes } from '../../reducers/types';
import { getTenenciaVehicleData } from '../../services/VehicleServices';
import TenureServiceMainSection from './TenureServiceMainSection';
import './ServiceSideSection.css';
import './TenureServiceSideSection.css';
import ServiceErrorSection from './ServiceErrorSection';
let tenureTimer = false;
const TenureServiceSideSection = ({ vehicle, foreigner, selected, setMainPanel, open }) => {
  let { dispatch } = useContext(AppContext);
  let { services: { tenencia_refrendo } } = vehicle;
  const loading = Object.entries(tenencia_refrendo).length == 0;
  const tenecia = loading ? [] : tenencia_refrendo;
  const reference = loading ? {} : Object.values(tenecia[0])[0];
  const noDebts = loading || foreigner ? false : reference.ejercicio == 0;
  const error = loading ? false : reference.ejercicio != 0 && reference.tenencia == undefined;
  const [current, setCurrent] = useState(null);

  useEffect(() => () => tenureTimer = true, [])

  useEffect(() => {
    if (open && loading) {
      tenureTimer = false;
      getVerificationData()
    } else
      tenureTimer = true
  }, [open, loading])

  useEffect(() => {
    if (selected == 'tenure') {
      if (noDebts || foreigner)
        setMainPanel(<TenureServiceMainSection noDebts={noDebts} foreigner={foreigner} />)
      else {
        setCurrent(tenecia[0])
      }
    } else {
      setCurrent(null)
    }
  }, [selected])

  useEffect(() => {
    if (current)
      setMainPanel(<TenureServiceMainSection tenureSelected={current} error={error} />)
  }, [current])

  const getVerificationData = async () => {
    const { success, data } = await getTenenciaVehicleData(vehicle.id)
    if (success && Object.entries(data.tenencia_refrendo).length != 0) {
      dispatch({
        type: vehicleTypes.updateTenure,
        payload: {
          id: vehicle.id,
          tenencia_refrendo: data.tenencia_refrendo
        }
      });
    } else {
      setTimeout(() => !tenureTimer && getVerificationData(), 1000 * 5);
    }
  }

  const handlerSelectTenueYear = (e, item) => {
    e.stopPropagation();
    setCurrent(item)
  }

  return (
    <div className="service-side-section">
      {
        loading ? (
          <div className="loading-container">
            <p className='title'>¡Estamos procesando tu información, espera un momento!</p>
            <div className="loading">
              <CircularProgress className="loading-icon" size={30} />
            </div>
          </div>
        ) : (
          <>
            <p className="title">Adeudos (2007 a 2022)</p>
            {
              error ? (
                <ServiceErrorSection type={'side'} />
              ) : foreigner ? (
                <div className="without-info-container">
                  <p className='title'>Servicio disponible solo para placas de la Ciudad de México.</p>
                </div>
              ) : (
                <>
                  {
                    noDebts ? (
                      <span className="side-variable">Sin adeudos</span>
                    ) : (
                      <Grid container className="years-container">
                        {
                          tenecia.map((item) => {
                            const year = Object.keys(item)[0];
                            const currentYear = current ? Object.keys(current)[0] : null;
                            return (<Grid container item xs={3}>
                              <span className={`tenure-year side-variable ${year == currentYear ? 'selected' : ''}`}
                                onClick={(e) => handlerSelectTenueYear(e, item)}
                              >
                                {year}
                                <FontAwesomeIcon className="icon" icon="arrow-right" />
                              </span>
                            </Grid>)
                          })
                        }
                      </Grid>
                    )
                  }
                </>
              )
            }
          </>
        )
      }
    </div>
  )
}

export default TenureServiceSideSection
