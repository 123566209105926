import { Grid } from '@mui/material';
import { useState } from 'react'
import { Images } from '../../../util';
import { Paypal_Button_Molecule } from '../../molecules/Paypal_Button_Molecule';
import FinishPaymentSection from './FinishPaymentSection';

import './PaypalPaymentSection.css';

const PaypalPaymentSection = ({ amount, handlerSubmit }) => {
  const [paypalData, setPaypalData] = useState(null)

  const onSubmit = () => {
    const success = handlerSubmit({ paypalData });
    if (!success) {
      console.error(message);
    }
  }

  return (
    <>
      <div className="payment-section">
        <p className='payment-section-title'>Pago con PayPal</p>
        <br />
        {
          paypalData ? (
            <Grid container className="edit-paypal-container" alignItems={'center'} justifyContent={'space-evenly'}>
              <Grid item xs={9}>
                <img src={Images.PAYMENT_PAYPAL}></img>
                <p>Ha ligado su cuenta de PayPal satisfactoriamente</p>
              </Grid>
              <Grid itemxs={3}>
                <button onClick={() => setPaypalData(null)}>
                  Editar
                </button>
              </Grid>
            </Grid>
          ) : (
            <Paypal_Button_Molecule
              amount={amount.toFixed(2)}
              onSuccess={(data) => {
                setPaypalData(data)
              }}
              onError={(capture) => {
                setPaypalData(null)
              }}
            />
          )

        }

      </div>
      <FinishPaymentSection
        amount={amount}
        enable={paypalData != null}
        processPayment={onSubmit}
      />
    </>
  )
}

export default PaypalPaymentSection
